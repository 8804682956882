@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-main-view {
    flex: 1 1;
    width: 100%;
    height: 100%;

    & > .c-view__content > .c-view__header > .c-view__main-header > .c-view__right,
    & > .c-view__content > .c-view__header > .c-view__main-header > .c-view__left {
        flex: 0 0 6rem;
        @include fixedWidth(6rem);
    }

    .bepe-textfield input.bepe-textfield__input {
        background: var(--black-150);
    }
}

@include desktop {
    
}