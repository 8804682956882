@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";

.c-icon-button {
    display: flex;
    gap: var(--margin-sm);
    align-items: center;

    outline: none;
    border: none;
    background-color: transparent;
    color: $white;
    font-size: 2.4rem;
    padding: $padding-lg;
    cursor: pointer;
    max-width: 100%;

    &__children {
        @include textSize(100);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &:disabled {
        opacity: .3;
        cursor: default;
    }

    &:enabled:hover {
        color: $grey-80;
    }
}