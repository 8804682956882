@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util';

.c-import-transfer-row {

    .c-transfer-type-icon {
        @include fixedHeight(3.2rem);
        margin-top: .4rem;
    }

    .c-import-table {
        &__date-input input {
            max-height: 4.1rem;
        }

        &__budgetItems,
        &__transfer-account {
            margin-top: 1px;
        }
    }
}