.c-transfer-budget-items-edit {
    display: flex;
    flex-direction: column;
    gap: var(--margin-sm);
    width: 100%;
    overflow: hidden;

    &__button {
        width: fit-content;
    }
}