@import '../../style/util';

.c-notification-bar {
    z-Index: 10000;
    position: fixed;
    bottom: 10rem;
    left: 0;
    width: 100vw;
    overflow: hidden;

    &--hidden {
        display: none;
        visibility: hidden;
    }

    &__container {
        display: flex;
        flex-direction: row-reverse;
        padding-right: var(--padding-lg);
        padding-left: var(--padding-lg);
        gap: var(--padding-md);
        overflow-x: scroll;
        transition: all .2s ease-in-out;
    }
}

@include desktop {
    .c-notification-bar {
        bottom: auto;
        left: auto;
        right: var(--padding-lg);
        top: var(--margin-lg);
        width: 35rem;

        &__container {
            padding: 0px;
            flex-direction: column;
            gap: var(--padding-lg); 
        }
    }
}