.c-account-summary {
  width: 100%;
  max-width: 40rem;
  background-color: var(--black-150);

  display: flex;
  flex-direction: column;
  gap: var(--padding-md);
  padding: var(--padding-md);
  cursor: pointer;

  &:hover {
    background-color: var(--grey-50);
  }

  &__liquidity,
  &__credits,
  &__savings,
  &__assets,
  &__total {
    display: flex;
    justify-content: space-between;
  }

  &__total {
    border-top: 1px solid var(--grey-50);
    padding-top: var(--padding-md);
  }
}