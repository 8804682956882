@import '../../style/util';

.c-progress-display {
    text-align: right;
    width: 100%;
    
    &:empty {
        background-color: var(--grey-50);
        border-radius: 4px;
        width: 8rem;
        background: linear-gradient(-45deg, var(--grey-50), var(--grey-60), var(--grey-50));
        animation: loadingText 2s ease infinite;
        background-size: 400% 400%;
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
        padding-right: .6rem;
        padding-left: .6rem;
    }

    &__progress-container {
        position: relative;
        height: .6rem;
    }

    &__center-container {
        position: relative;
        height: .6rem;
        width: 100%;
    }

    &__background-bar {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: .3rem;
        background-color: var(--white);
        opacity: .4;
    }

    &__progress-bar {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: .3rem;
        background-color: var(--white);
    }

    &--caution .c-progress-display__progress-bar {
        background-color: var(--signal-danger);
    }
    &--warning .c-progress-display__progress-bar {
        background-color: var(--signal-warning);
    }
    &--ok .c-progress-display__progress-bar {
        background-color: var(--signal-confirm);
    }

    &__expect-container {
        position: relative;
        height: .6rem;
    }
    &__expect-marker,
    &__hundred-marker,
    &__zero-marker {
        position: absolute;
        @include fixedWidth(0);
        @include fixedHeight(0);
        border: .6rem solid transparent;
        border-top-color: .6rem solid var(--white);
        margin-bottom: -.6rem;
        margin-left: -.6rem;
        margin-right: -.6rem;
    }

    &__expect-marker {
        border-top-color: var(--signal-warning);
    }


    &__zero-label,
    &__hundred-label,
    &__expect-label {
        position: absolute;
        transform: translateX(-50%);
        padding-top: 2px;
        font-size: var(--text-75-size);
        line-height: 1;
    }
    
}

@keyframes loadingText {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}