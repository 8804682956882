.c-tab {
    padding: var(--padding-md) var(--padding-lg);
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    cursor: pointer;

    &--active {
        border-bottom: 2px solid var(--accent);
        cursor: auto;
    }
}

// &:hover {
//     background-color: var(--grey-50);
// }
