@import '../../../style/util'; 

.c-transfer-tile {
    display: flex;
    flex-direction: column;
    gap: var(--padding-md);
    background-color: var(--black-150);
    padding: calc(var(--padding-md) - 1px);
    cursor: pointer;
    border: 1px solid var(--black-150);

    &:hover {
        background-color: var(--grey-50);
    }

    &--selected {
        border: 1px solid var(--accent);
        
        &,
        &:hover {
            background-color: var(--black-100);
        }
    }

    &__date {
        flex: 0 0;
        color: var(--grey-60);
    }

    &__core {
        display: flex;
        gap: var(--padding-md);
    }

    &__description {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-2);
        overflow: hidden;

    }
    &__row-1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: var(--padding-md);
    }

    &__row-2 {
        width: 100%;
        display: flex;
        gap: var(--margin-md);
        align-items: center;
    }

    &__additional-info {
        flex: 1 1;
        display: flex;
        gap: var(--margin-sm);
        overflow: scroll;
    }

    &__accounts,
    &__budget-items {
        display: flex;
        gap: var(--margin-sm);
    }

    &__seperator {
        @include fixedHeight(2rem);
        @include fixedWidth(1px);
        background-color: var(--grey-60);
    }

    &__budget-items {
        .c-currency-display {
            display: none;
        }
    }

    &__value-container {
        flex: 0 0;
        display: flex;
        gap: var(--padding-md);
        align-items: center;
    }
    &__value-total {
        color: var(--grey-60);
    }
}
