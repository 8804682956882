@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-sub-menu {
    background-color: $black-150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 6rem);
    border-bottom: 2px solid $black-100;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-top: $padding-xl;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__profile {
        position: absolute;
        left: $padding-xl;
        top: $padding-xl;

    }

    &__close-button {
        position: absolute;
        right: 0;
        top: 0;
        outline: none;
        border: none;
        background-color: transparent;
        color: $white;
        font-size: 2.4rem;
        padding: $padding-xl;
    }

    .c-menu-item {
        width: 100%;
        border-top: 1px solid $grey-50;
        border-bottom: 2px solid transparent;

        &__container {
            flex-direction: row;
            gap: $spacing-16;
            justify-content: flex-start;
            align-items: center;
            padding: $padding-lg;
            padding-left: $padding-xl;
            padding-right: $padding-xl;
            width: 100%;
            padding-left: calc($padding-xl - 4px);
            border-left: 4px solid transparent;

        }

        &--selected {
            .c-menu-item__container {
                border-left: 4px solid $accent;
            }
            border-bottom: 2px solid transparent;
        }
    }
}