@import '../style/util';

.c-layout {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-height: 100%;
    min-width: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
}


@include desktop {
    .c-layout {
        flex-direction: row-reverse;
        height: 100%;
    }
}