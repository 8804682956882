@import '../../../style/util'; 

.c-account-tile {
    display: flex;
    gap: var(--padding-md);
    background-color: var(--black-150);
    padding: calc(var(--padding-md) - 1px);
    cursor: pointer;
    border: 1px solid var(--black-150);

    &:hover {
        background-color: var(--grey-50);
    }

    &--selected {
        border: 1px solid var(--accent);
        
        &,
        &:hover {
            background-color: var(--black-100);
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;

        &--ok {
            background-color: var(--status-ok);
        }

        &--warning {
            background-color: var(--status-warning);
            color: var(--black-150);
        }
        &--info {
            background-color: var(--status-info);
        }
    }

    &__content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-2);

        div {
            display: flex;
            justify-content: space-between;
        }
    }
}
