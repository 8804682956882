@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util';

.c-import-modal {
    .c-modal {
        max-width: calc(100% - var(--margin-lg));
        max-height: calc(100% - var(--margin-lg));
    }

    .bepe-uploadfield {
        height: 40rem;
    }

    .c-import-modal__spinner {
        @include center();
    }

    .c-import-table {

        &__row,
        &__header {
            display: flex;
            gap: var(--padding-sm);
            align-items: flex-start;
            padding: var(--spacing-4) 0;
            border-bottom: 1px solid var(--black-150);
            border-top: 1px solid transparent;
        }

        &__header {
            & > div {
                font-weight: var(--font-bold);
                color: var(--grey-80);
                @include textSize("75");
            }
        }

        &__icon {
            @include fixedWidth(3.2rem);
            flex: 0 0 3.2rem;
        }

        &__date,
        &__date-input {
            flex: 0 0 15rem;
            width: 15rem;
        }

        &__description {
            flex: 1 1 70rem;
            width: 70rem;
        }

        &__description-input {
            width: 100%;
        }

        &__value,
        &__value-input.bepe-textfield {
            width: 9.4rem;
            flex: 0 0 9.4rem;
        }

        &__transfer-account {
            width: 15.1rem;
            flex: 0 0 15.1rem;
        }

        &__budgetItems {
            width: 34rem;
            flex: 0 0 34rem;
        }

        &__comment,
        &__comment-input {
            width: 15rem;
            flex: 0 0 15rem;
        }

        &__actions {
            width: 9rem;
            flex: 0 0 9rem;
            display: flex;
            gap: var(--spacing-4);

            & .c-icon-button {
                padding-left: .8rem;
                padding-right: .8rem;
                font-size: var(--text-75-font-size);
                margin: -.4rem;
            }
        }
    }

}

@include desktop {

}
