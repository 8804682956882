@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import "../../../style/util";

.c-selection-dropdown {
    flex: 1 1;
    overflow: hidden;
    max-width: fit-content;

    &__button {
        position: relative;
        cursor: pointer;
    }


    &__close-layer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1019;
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
    }

    &__container {
        background-color: $black-150;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100%);
        overflow:hidden;
        padding: $padding-md;
        padding-top: $padding-sm;
        display: flex;
        flex-direction: column;
        gap: $padding-md;
        opacity: 0;
        visibility: hidden;
        border-bottom: 2px solid $black-100;
        z-index: 1020;

        .bepe-textfield {
            flex: 1 1;
        }

        hr {
            margin-left: -$padding-md;
            margin-right: -$padding-md;
            border: 0;
            border-top: 2px solid $black-100;
        }

        &--open {
            opacity: 1;
            visibility: visible;
        }
    
    }

    &--open {
        .c-selection-dropdown__close-layer {
            opacity: 1;
            visibility: visible;
        }
    }


    &__search-container {
        display: flex;
        gap: $padding-md;
    }


    &__content {
        display: flex;
        flex-direction: column;
        gap: $padding-md;
        overflow-y: scroll;

        .c-selection-item {
            justify-content: flex-start;
        }
    }

    &__delesect-button {
        gap: var(--margin-sm);
    }
}

@include desktop {
    .c-selection-dropdown {
        &__container {
            position: absolute;
            top: initial;
            left: initial;
            width: 30rem;
            height: 30rem;
            border: 1px solid $grey-50;

            hr {
                border: 0;
                border-top: 1px solid $grey-50;
            }
        
        }
    }
}