@import '../../../../style/util'; 

.c-budget-item-module {
    display: flex;
    flex-direction: column;
    gap: var(--padding-md);
    background-color: var(--black-150);
    padding: calc(var(--padding-md) - 1px);
    cursor: pointer;
    border: 1px solid var(--black-150);
    overflow: hidden;

    &:hover {
        background-color: var(--grey-50);
    }

    &__core {
        display: flex;
        gap: var(--padding-md);
    }

    &__labels {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: var(--padding-sm);
        overflow-x: scroll;
    }

    &__target-actual {
        min-width: 5.8rem;
    }

    &--selected {
        border: 1px solid var(--accent);

        &,
        &:hover {
            background-color: var(--black-100);
        }
    }

    &__content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-2);

        & > div {
            display: flex;
            justify-content: space-between;
        }
    }

    &__progress {
        display: flex;
        gap: var(--margin-sm);
        align-items: center;
    }
}
