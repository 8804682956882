@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util';

.c-import-transfer {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid var(--accent);
        border-top: 1px solid var(--accent);
    }
}