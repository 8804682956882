.c-transfer-list-module {
    min-height: 30rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--padding-lg);
    padding-bottom: var(--padding-md);

    &__search {
        display: flex;
        justify-content: space-between;
        gap: var(--padding-md);
        .bepe-textfield {
            flex: 1 1;
        }
    }

    &__search-icon {
        padding-top: var(--spacing-4);
        padding-bottom: var(--spacing-4);
        margin-right: calc(-1 * var(--padding-lg));
        margin-left: calc(-1 * var(--padding-md));
    }

    &__list {
        flex: 1 1;
        overflow: hidden;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--padding-md);
        margin-top: calc(-1 * var(--padding-md));

        p {
            padding: var(--padding-md);
            font-weight: bold;
        }
    }
}