@import '../../style/util';

.c-content--loading {
        background-color: var(--grey-50);
        border-radius: 4px;
        @include fixedWidth(8rem);
        background: linear-gradient(-45deg, var(--grey-50), var(--grey-80), var(--grey-50));
        animation: contentLoading 2s ease infinite;
        background-size: 400% 400%;
        min-height: calc(var(--text-100-line-height) - 2px);
}

@keyframes contentLoading {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}