@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-menu {
    display: flex;
    gap: 0;
    background-color: $black-150;
    height: 6rem;
    min-height: 6rem;
    width: 100%;
    z-index: 100;

    .c-date-selector {
        margin-top: -1.2rem;
    }

    &__profile {
        padding: $padding-md $padding-lg;
        color: $grey-80;
    }

    &__spacer {
        flex: 1 1;
    }

    &__add-button {
        width: 20%;
        position: relative;

        .c-menu-item {
            z-index: 1000;
            position: absolute;
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            background-color: $primary-med;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-100%);
            &__container {
                justify-content: center;
                align-items: center;
            }

            &__icon {
                font-size: 3.2rem;
            }

            &__label {
                display: none;
            }

            &:active {
                background-color: $primary-dark;
            }

            &--selected {
                border-bottom: 2px solid transparent;
            }
        }
    }

    &--sub-menu-open .c-menu__add-button .c-menu-item {
        transform: translate(-50%,-50%);
    }
}

@include desktop {
    .c-menu {

        h2 {
            padding-left: $padding-xl;
            margin-top: $margin-lg + $margin-sm;
            margin-bottom: $margin-md;
        }
        flex-direction: column;
        height: 100%;
        width: 28rem;
    }
}