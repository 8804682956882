@import '../../../../style/util'; 

.c-budget-label-module-mini {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    overflow: hidden;
    width: fit-content;
    padding: 0 var(--spacing-4);
    border-radius: 2px;

    &__icon {
        font-size: var(--text-75-size);
        display: flex;
        justify-content: center;
        align-items: center;
        @include fixedWidth(2rem);
        @include fixedHeight(2rem);
    }

    &__name {
        flex: 1 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
