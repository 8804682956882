@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util';

.c-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $black-150;
    width: 100%;
    height: 100%;

    &--in-place {
        & .c-modal__title {
            display: none;
        }

        & .c-modal__bar {
            display: none;
        }

        & .c-modal__main {
            padding: 0px;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }
    &__backdrop.c-modal__backdrop--in-place {
        position: initial;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__close-button {
        position: absolute;
        right: 0;
        top: 0.6rem;
    }

    &__title {
        text-align: center;
        padding: $padding-lg;
        border-bottom: 2px solid $black-100;
    }

    &__main {
        flex: 1 1;
        padding: $padding-lg;
        background-color: $black-100;
        overflow-y: scroll;
    }
    &__bar {
        display: flex;
        gap: $padding-lg;
        justify-content: space-between;
        padding: $padding-lg;
        border-top: 2px solid $black-100;
    }
}

@include desktop {
    .c-modal {
        width: fit-content;
        height: fit-content;
        max-width: 80%;
        max-height: 80%;
        min-width: 72rem;
        min-height: 48rem;
    
        &__title {
            text-align: left;
        }
    }
}