@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util'; 

.c-account-details {
    display: flex;
    flex-direction: column;
    gap: var(--margin-md);
    overflow: hidden;
    height: 100%;
    justify-content: flex-start;

    h2 {
        display: flex;
        gap: var(--spacing-8);
    }

    &__header {
        flex-shrink: 0;
        height: fit-content;
        display: flex;
        align-items: center;
        gap: var(--margin-md);
        overflow: hidden;
        width: 100%;
    }

    &__progress-actual,
    &__target-forecast {
        flex: 1 1;
        display: flex;
        gap: var(--margin-md);
        justify-content: space-between;
        & > div {
            min-width: 4.5rem;
        }
    }

    &__progress-element {
        flex: 1 1;
    }

    &__transfers.bepe-form-element {
        width: 100%;
        flex: 1 1;
        overflow: hidden;
    }

    &__open-items,
    &__reserve-funds {
        height: 100%;
        overflow-y: scroll;
    }

    &__open-items-scroll,
    &__reserve-funds-scroll {
        display: flex;
        flex-direction: column;
        gap: var(--margin-sm);
    }

    &__open-transactions.bepe-form-element {
        // align-self: flex-end;
        min-width: 6rem;
        width: 100%;
        border-bottom: 1px solid var(--grey-50);
        padding-bottom: var(--padding-sm);
        margin-bottom: var(--padding-sm);

        & > label {
            width: 100%;
            text-align: right;
        }
    }
}

@include desktop {

}
