@import '../style/util'; 

.c-type-icon {
    font-size: var(--text-100-size);
    display: flex;
    justify-content: center;
    align-items: center;
    @include fixedWidth(4.8rem);
    @include fixedHeight(4.8rem);

    &--small {
        font-size: var(--text-75-size);
        @include fixedWidth(2rem);
        @include fixedHeight(2rem);
    }
}