@import '../../../../style/util'; 

.c-budget-item-tile-mini {
    display: flex;
    align-items: center;
    gap: var(--spacing-16);
    overflow: hidden;
    width: fit-content;

    &__icon-name-container {
        flex: 1 1;
        display: flex;
        align-items: center;
        gap: var(--spacing-4);
        overflow: hidden;
    }

    &__name {
        flex: 1 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &--show-value {
        justify-content: space-between;
        width: 100%;
    }
}
