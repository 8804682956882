@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.c-notification-tile {
    @include fixedWidth(100%);
    animation: fadeIn .4s;
    display: flex;
    gap: var(--padding-md);
    padding: var(--padding-md);
    align-items: center;
    transition: all .2s ease-in;
    @include show();
    overflow: hidden;

    &--closing {
        @include hidden();
    }

    &--danger {
        border-left: 6px solid var(--signal-danger);
        background-color: transparentColor($signal-danger, .7);
    }

    &--info {  
        border-left: 6px solid var(--signal-info);
        background-color: transparentColor($signal-info, .7);
    }
    &--confirm {  
        border-left: 6px solid var(--signal-confirm);
        background-color: transparentColor($signal-confirm, .7);
    }
    &--warning {  
        border-left: 6px solid var(--signal-warning);
        background-color: transparentColor($signal-warning, .7);
    }
    
    &__message {
        flex: 1 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}