@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util';

.c-contextmenu-item {
    outline: none;
    border: none;
    padding: var(--padding-lg);
    background-color: var(--black-150);
    color: var(--white);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: var(--padding-lg);
    width: 100%;
    white-space: nowrap;

    @include textSize(100);

    &:hover {
        background-color: var(--grey-50);
    }

    &--dangerous {
        color: var(--signal-danger);
        &:hover {
            background-color: var(--white);
        }
    }
}


@include desktop {
    .c-contextmenu-item {
    }
}