@import "../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../style/util'; 

.c-budgeting {
  &__context {
    display: flex;
    gap: var(--margin-md);
    align-items: center;
    margin-right: var(--margin-lg);

    .bepe-button {
      display: flex;
      align-items: center;
    }
  }

  & .c-selection-dropdown {
    white-space: nowrap;
  }
}

@include desktop {
  .c-budgeting {
    & .c-selection-dropdown .c-selection-dropdown__container.c-selection-dropdown__container--open {
      left: auto;
      right: 0;
    }

    &.c-main-view > .c-view__content > .c-view__header > .c-view__main-header > .c-view__right {
      flex: 1 1;
      max-width: fit-content;
    }
  }
  
}
