@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util'; 

.c-budget-plan {
  display: flex;
  gap: var(--margin-lg);
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  .c-budget-plan-column {
    flex: 1 1 6.666666666666667%;
  }

  &__item-granularity {
    display: flex;
    gap: var(--padding-sm);
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
  }

  &__granularity-mode {
    align-items: center;
    flex: 0 0;
    display: flex;
    gap: var(--padding-xs);
  }

  &__settings {
    flex: 1 1 13%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: var(--padding-sm);
  }
  
  &__accounts {
    display: flex;
    gap: var(--padding-xs);
    overflow: hidden;
    align-items: center;
  }

  &__columns {
    flex: 1 1 87%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__actual,
  &__target-actual,
  &__target-actual-total,
  &__target,
  &__savings,
  &__savings-total {
    display: flex;
    gap: var(--padding-sm);
    overflow: hidden;
  }

  &__target-actual-total {
    border-bottom: 1px solid var(--black-150);
  }

  &--edit .c-icon-button {
    padding: 0;
    font-size: var(--text-100-size);
  }

  &__value-field.c-currency-textfield.bepe-textfield {
    @include fixedWidth(auto);
    & input.bepe-textfield__input {
      padding: 0 var(--spacing-4);
      @include textSize(75);
      text-align: right;
      @include fixedWidth(100%);
    }
  }
}