.c-selection-list-entry {
    flex: 1 1;
    display: flex;
    align-items: center;
    gap: var(--padding-lg);
    overflow: hidden;

    &__delete-icon {
        flex: 0;
        font-size: var(--text-75-font-size);
        margin: -1.6rem;
        margin-left: -1rem;
    }
}