@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-view {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    gap: 0;
    overflow: hidden;


    .c-detail-view--open ~ .c-view__content {
        @include fixedWidth(0%);

        .c-view__header {
            @include fixedWidth(100vw);
        }
        .c-view__main {
            @include fixedWidth(100vw);
        }
    }


    &__content {
        transition: all .2s ease-in-out;
        @include fixedWidth(100%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        overflow: hidden;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 0rem;
        height: calc(7.4rem + 0.8rem);

        .c-date-selector {
            margin-top: -0.8rem;
        }
    }

    &__main-header {
        display: flex;
        @include fixedWidth(100vw);
        align-items: center;
        gap: var(--padding-md);
        h1 {
            flex: 1 1 auto;
            width: fit-content;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            overflow: hidden;
            // display: flex;
            // justify-content: center;
            // align-items: center;
        }
    }

    &__right,
    &__left {
        flex: 0 0 8rem;
        @include fixedWidth(8rem);
        display: flex;
        min-height: 5.8rem;
        justify-content: flex-start;
    }

    &__right {
        justify-content: flex-end;
    }

    &__main {
        @include fixedWidth(100vw);
        flex: 1 1;
        padding: var(--padding-lg);
        overflow: hidden;
    }
}

@include desktop {
    .c-view {
    
        .c-detail-view--open ~ .c-view__content {
            @include fixedWidth(30%,35rem);
    
            .c-view__header {
                @include fixedWidth(100%);
            }
            .c-view__main {
                @include fixedWidth(100%);
            }
        }

        &__header {
            @include fixedWidth(100%);
            height: 7.4rem;
        }
    
        &__main-header {
            @include fixedWidth(100%);
            h1 {
                justify-content: flex-start;
                text-align: left;
                padding-left: var(--margin-lg);
            }
        }
        &__left:empty {
            display: none;
        }
    
        &__main {
            @include fixedWidth(100%);
            padding: var(--padding-xl);
        }
    }
}