@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util'; 

.c-transfer-details--view {

    .c-account-tile-mini,
    .c-budget-item-tile-mini {
        padding: var(--padding-sm);
    }

}

.c-transfer-details {
    display: flex;
    flex-direction: column;
    gap: var(--margin-lg);

    &__row-1 {
        display: flex;
        justify-content: space-between;
        gap: var(--margin-md);
        overflow: hidden;
        width: 100%;
    }

    &__icon-date {
        display: flex;
        gap: var(--margin-md);

        .c-round-icon {
            font-size: 1.4rem;
        }
    }

    &__accounts {
        width: 100%;
        display: flex;
        gap: var(--margin-md);
    }

    &__budget-items .c-content {
        display: flex;
        flex-direction: column;
        gap: var(--margin-sm);
    }

    &__bank-details {
        border-collapse: collapse;
        td, th {
            padding: var(--padding-xs) var(--padding-md);
        }
        th {
            padding-left: 0px;
        }
        td {
            padding-right: 0px;
        }
    }
}

@include desktop {

}
