.c-transfer-bank-details {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    overflow: hidden;

    td, th {
        padding: var(--padding-xs) var(--padding-md);
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    th {
        padding-left: 0px;
    }
    td {
        padding-right: 0px;
    }
}