@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util';

.c-import-collision-row {
    .c-transfer-type-icon {
        @include fixedHeight(3.2rem);
        margin-top: .4rem;
    }

    .c-import-table {
        &__date,
        &__description,
        &__value,
        &__transfer-account,
        &__budgetItems,
        &__comment {
            height: 4.1rem;
            padding: var(--padding-md) var(--padding-lg);
        }

        &__date {
            padding: var(--padding-md) var(--padding-lg);
        }

        &__transfer-account,
        &__budgetItems {
            display: flex;
            gap: var(--margin-sm);
            align-items: center;
        }

        &__description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}