@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-detail-view {
    @include fixedWidth(0);
    overflow: hidden;
    transition: all .2s ease-in-out;

    &--open {
        @include fixedWidth(100%);
    }

    .c-view__header {
        height: 5.6rem;
        h1 {
            font-size: var(--heading-50-size);
            line-height: var(--heading-50-line-height);
            font-weight: var(--font-normal);
        }
    }

    .c-view__left,
    .c-view__right {
        flex: 0 0 10rem;
        @include fixedWidth(10rem);
        .c-icon-button__children {
            @include textSize(75);
        }
    }

    .bepe-textfield input.bepe-textfield__input {
        background: var(--black-150);
    }

}

@include desktop {
    .c-detail-view {
        box-shadow: -2px 0px 3px var(--black-150);

        &--open {
            @include fixedWidth(70%, auto);
        }
    
        .c-view__header {
            padding-left: var(--margin-sm);
            height: 7.4rem;
            h1 {
                padding-left: 0px;
                font-size: var(--heading-100-size);
                line-height: var(--heading-100-line-height);
                font-weight: var(--font-light);
            }
        }
    
        .c-view__left {
            flex: 0 0 4.7rem;
            @include fixedWidth(4.7rem);
        }
    
    }
}