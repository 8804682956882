@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util';

.c-date-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__label.bepe-button {
        padding: 0;
        height: auto;
        @include textSize(75);
        color: var(--primary-med);
        background-color: transparent;

        &:hover {
            color: var(--primary-light);
        }
        &:active {
            color: var(--primary-dark);
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: var(--padding-lg);

        position: absolute;
        top: 2.4rem;
        background-color: var(--black-150);
        padding: var(--padding-lg);
        border: 1px solid var(--grey-50);
        box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.2);

        visibility: hidden;
        opacity: 0;
        z-index: 500;
    }

    &__close-layer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 499;
        background-color: var(--white);
        visibility: hidden;
        opacity: 0;
    }

    &__kind {
        display: flex;
        gap: 1px;

        .bepe-button {
            &.c-date-selector__month-selector {
                border-radius: var(--border-interactive) 0 0 var(--border-interactive);
            }
            &.c-date-selector__year-selector {
                border-radius: 0 var(--border-interactive) var(--border-interactive) 0;
            }
            &.c-date-selector__month-selector--selected,
            &.c-date-selector__year-selector--selected {
                background-color: var(--accent);
                color: var(--white);
            }
        }
    }

    &__date.bepe-form-element {
        display: flex;
        flex-direction: column;
        gap: var(--padding-sm);
        width: 100%;
    }

    &__today--selected.bepe-button {
        background-color: var(--accent);
        color: var(--white);
    }

    &--open {
        .c-date-selector__container,
        .c-date-selector__close-layer {
            visibility: visible;
            opacity: 1;
        }
        .c-date-selector__close-layer {
            opacity: .5;
        }

    }
    

}

@include desktop {
    .c-date-selector.c-desktop-only {
        display: flex !important;
        width: 100%;
    }
}