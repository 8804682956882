@import "../../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../../style/util'; 

.c-budget-item-details {
    overflow: hidden;
    height: 100%;

    &__container {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--margin-md);
        justify-content: flex-start;
    }

    h2 {
        display: flex;
        gap: var(--spacing-8);
    }

    &__header {
        flex-shrink: 0;
        height: fit-content;
        display: flex;
        align-items: center;
        gap: var(--margin-md);
        overflow: hidden;
        width: 100%;
    }

    &__progress-transactions {
        flex: 1 1;
    }

    &__progress-element {
        flex: 1 1;
    }

    &__progress-transactions,
    &__planned {
        display: flex;
        gap: var(--margin-md);
        justify-content: space-between;
    }

    &__planned-transactions,
    &__current-transactions,
    &__actual-target-last-month {
        min-width: 7.5rem;
        &.bepe-form-element > label {
            left: auto;
            right: 0;
        }
    }

    &__current-transactions {
        min-width: 7.5rem;
    }

    &__transfers.bepe-form-element {
        width: 100%;
        flex: 1 1;
        overflow: hidden;
    }

    &__labels {
        width: 100%;
    }

    &__label-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--padding-xs);
        width: 100%;
    }

    &__label {
        width: 100%;
        gap: var(--margin-lg);

        &--other {
            .c-budget-label-module-mini {
                background: var(--status-light-neutral);
            }
            opacity: .7;
        }
    }
}

@include desktop {

}
