@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-transfer-edit-view {

    &__container {
        display: flex;
        flex-direction: column;
        gap: var(--margin-lg);
        overflow: scroll;
        width: 100%;
    }

    &__row-1 {
        display: flex;
        justify-content: space-between;
        gap: var(--margin-md);
        overflow: hidden;
        width: 100%;
        align-items: flex-start;

        input {
            height: 4.1rem;
        }
    }

    &__icon-date {
        flex: 1 1;
        overflow: hidden;
        align-items: flex-end;
        display: flex;
        gap: var(--margin-md);
    }

    &__date {
        flex: 1 1;
    }
    &__date,
    &__date-input {
        width: auto;
    }

    &__value,
    &__value-input {
        min-width: 8.8rem;
        width: 8.8rem;

        input {
            text-align: right;
            padding-left: 0;
        }
    }

    &__description,
    &__description-input,
    &__comment,
    &__comment-input,
    &__budgetItems {
        &.bepe-form-element {
            width: 100%;
            overflow: hidden;
        }
    }

    &__accounts {
        width: 100%;
        display: flex;
        gap: var(--margin-md);
        overflow: hidden;
    }

    &__account,
    &__transfer-accounts {
        flex: 1 1;
        overflow-x: hidden;
    }
    
    &__budget-items .c-content {
        display: flex;
        flex-direction: column;
        gap: var(--margin-sm);
    }

    &__transfered-account-container {
        display: flex;
        justify-content: space-between;
        gap: var(--padding-lg);

        & button.c-icon-button {
            font-size: var(--text-75-font-size);
            margin: -1.6rem;
            margin-left: -1rem;
        }
    }
}

    .c-modal__main {

        

        &__icon-date {
            display: flex;
            gap: var(--margin-md);

            .c-round-icon {
                font-size: 1.4rem;
            }
        }

        &__accounts {
            width: 100%;
            display: flex;
            gap: var(--margin-md);
        }

        &__bank-details {
            border-collapse: collapse;
            td, th {
                padding: var(--padding-xs) var(--padding-md);
            }
            th {
                padding-left: 0px;
            }
            td {
                padding-right: 0px;
            }
        }

        label {
            white-space: nowrap;
        }
}

@include desktop {
    .c-transfer-edit-view {
        &__accounts {
            overflow: initial;
        }

        &__account,
        &__transfer-accounts {
            overflow-x: initial;
        }
    }
    
}
