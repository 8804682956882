.c-transfer-type-icon {
    border-radius: 50%;

    &--clickable {

        &.status--caution {
            border: 1px solid var(--status-light-caution);
        }
        &.status--ok {
            border: 1px solid var(--status-light-ok);
        }
        &.status--warning {
            border: 1px solid var(--status-light-warning);
        }
        &.status--info {
            border: 1px solid var(--status-light-info);
        }
        &.status--neutral {
            border: 1px solid var(--status-light-neutral);
        }
    }

    &--small {
    }
}