@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util'; 

.c-budget-plan-list {
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: var(--margin-lg);

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--margin-lg);
        padding-bottom: 4rem;
    }

    &__loading.bepe-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

}



@include desktop {

}
