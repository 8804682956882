.c-budget-plan-category-list {
  display: flex;
  flex-direction: column;
  gap: var(--margin-md);
  overflow: hidden;

  .c-budget-plan-column {
    flex: 1 1 7.142857142857143%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
  }

  &__header {
    .c-budget-category-header__title {
      flex: 1 1 calc(13% + 5.8%);
      overflow: hidden;
    }
    .c-budget-category-header__columns {
      flex: 1 1 calc(87% - 5.8%);
      overflow: hidden;
      gap: var(--padding-sm);
    }
  }
}