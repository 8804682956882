@import '../../../style/util'; 

.c-account-tile-mini {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    overflow: hidden;

    &__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
