@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../style/util'; 

.c-currency-textfield.bepe-textfield {
    min-width: 8.8rem;
    width: 8.8rem;

    input {
        text-align: right;
        padding-left: 0;

        &[type=number]::-webkit-inner-spin-button, 
        &[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
}

@include desktop {

}
