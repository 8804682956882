@import '../../../style/util';

.c-contextmenu {
    position: relative;
    margin-right: var(--margin-sm);
    border: 1px solid transparent;

    &__container {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        z-index: 500;
        right: -1px;
        border: 1px solid var(--grey-50);
        background-color: var(--black-150);
        display: flex;
        flex-direction: column;
        gap: 0;

        & > *:not(:last-child) {
            border-bottom: 1px solid var(--grey-50);
        }

        box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.2);
    }

    &__close-layer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 499;
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
    }
    
    &--open {
        background-color: var(--grey-50);
        border: 1px solid var(--grey-50);
        border: 1px solid var(--grey-50);
        z-index: 501;

        & .c-contextmenu__container,
        & .c-contextmenu__close-layer {
            opacity: 1;
            visibility: visible;
        }
    }
}
