@import '../../../style/util';

.c-selection-item {
    background-color: var(--black-100);
    border: 1px solid var(--grey-50);
    cursor: pointer;
    padding: var(--padding-md);
    color: var(--grey-95);
    &:enabled:hover {
        background-color: var(--grey-50);
    }
    @include fixedHeight(3.8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    &--selected {
        border: 1px solid var(--accent);
        &,
        &:enabled:hover {
            background-color: var(--black-100);
        }
    }

    &--dashed {
        border: 1px dashed var(--grey-50);

        &.c-selection-item--selected {
            border: 1px dashed var(--accent);
        }
    }
}


@include desktop {
    .c-selection-item {
        &--preselected {
            border: 1px dashed var(--accent);
        }
    }
}
