@import "../../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../../style/util'; 

.c-budget-category-list {
    display: flex;
    flex-direction: column;
    gap: var(--margin-md);
    flex: 1 1;
    overflow: hidden;

    &__actual {
        font-size: var(--heading-50-size);
    }

    &__content {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: var(--margin-sm);

        &--collapsed {
            max-height: 0;
        }
    }

}



@include desktop {
    .c-account-list__sublist-content {
        max-width: 40rem;
    }
}
