@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import "../../style/util";

.c-picker {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1px;

    &__previous,
    &__next {
        background-color: var(--grey-80);
        color: var(--black-100);
        display: flex;
        justify-content: center;
        grid-gap: 1.6rem;
        gap: 1.6rem;
        margin: 0;
        border-width: 0px;
        border-style: none;
        outline: none;
        transition: color 0.2s ease, background-color 0.2s ease, filter 0.2s ease;
        text-align: center;
        cursor: pointer;
        border-radius: var(--border-interactive);
        &:hover {
            color: initial;
            filter: brightness(110%);
        }
        padding-top: var(--padding-md);
        padding-bottom: var(--padding-md);
    }

    &__previous {
        border-radius: var(--border-interactive) 0 0 var(--border-interactive);
    }

    &__next {
        border-radius: 0 var(--border-interactive) var(--border-interactive) 0;
    }

    &__button.bepe-button {
        flex: 1 1;
        border-radius: 0;
        align-items: center;

        &:disabled {
            opacity: 1;
        }
    }
}
