$desktop-width: 960px;

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$desktop-width}) {
        @content;
    }
}

@mixin fixedWidth($size, $minSize:$size) {
    min-width: $minSize;
    max-width: $size;
    width: $size;
}

@mixin fixedHeight($size, $minSize:$size) {
    min-height: $minSize;
    max-height: $size;
    height: $size;
}
