.c-selection-list {
    display: flex;
    flex-direction: column;
    gap: var(--margin-sm);
    width: 100%;
    overflow: hidden;

    &__add-button {
        width: fit-content;
        gap: var(--margin-sm);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}