@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util'; 

.c-transfer-list {
    display: flex;
    flex-direction: column;
    gap: var(--margin-sm);
    height: 100%;
    overflow: hidden;

    hr {
        border: 0;
        border-top: 2px solid var(--black-150);
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--margin-lg);
        overflow-y: scroll;
    }

    &__sublist {
        display: flex;
        flex-direction: column;
        gap: var(--margin-md);
    }

    &__sublist-title {
        width: 100%;
        border-bottom: 1px solid var(--grey-60);

    }

    &__sublist--ok .c-account-list__sublist-title {
        border-bottom: 1px solid var(--status-ok);
    }
    &__sublist--warning .c-account-list__sublist-title {
        border-bottom: 1px solid var(--status-warning);
    }
    &__sublist--info .c-account-list__sublist-title {
        border-bottom: 1px solid var(--status-info);
    }

    &__sublist-content {
        display: flex;
        flex-direction: column;
        gap: var(--margin-sm);
    }

    &__loading.bepe-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}



@include desktop {
    .c-account-list__sublist-content {
        max-width: 40rem;
    }
}
