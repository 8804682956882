.c-budget-category-header {
  width: 100%;
  overflow: hidden;
  display: flex;
  gap: var(--margin-lg);
  justify-content: space-between;
  align-items: center;

  &__icon {
      font-size: var(--heading-50-size);
      display: flex;
      justify-content: center;
      align-items: center;
  }

  &__title {
      flex: 1 1;
      display: flex;
      gap: var(--margin-sm);
      overflow: hidden;
  }

  &__name {
      flex: 1 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }

  &__columns {
    display: flex;
    gap: var(--margin-md);
    align-items: flex-end;
  }

  &--ok {
      border-bottom: 1px solid var(--status-ok);

      .c-budget-category-header__icon {
          color: var(--status-ok);
      }
  }
  &--warning {
      border-bottom: 1px solid var(--status-warning);
      .c-budget-category-header__icon {
          color: var(--status-warning);
      }
  }
  &--info {
      border-bottom: 1px solid var(--status-info);
      .c-budget-category-header__icon {
          color: var(--status-info);
      }
  }
  &--caution {
      border-bottom: 1px solid var(--status-caution);
      .c-budget-category-header__icon {
          color: var(--status-caution);
      }
  }

}