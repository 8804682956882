.c-budget-selection-list-entry {
    width: 100%;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--padding-lg);
    overflow: hidden;

    &__selections {
        flex: 1 1;
        display: flex;
        align-items: center;
        gap: var(--padding-sm);
        overflow: hidden;
    }

    &__value {
        flex: 0 0;
    }
}