.c-login-layout {
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--container-margin);
        padding: var(--container-margin);

        section {
            padding: var(--container-padding);
        }

    }

    &__form,
    &__last-profiles {
        background-color: var(--black-150);
        display: flex;
        flex-direction: column;
        gap: var(--margin-md);
        width: 100%;
        max-width: 32rem;
        border-radius: 3px;
    }

    &__form {
        align-items: center;

        .bepe-textfield {
            min-width: 100%;
        }

        .bepe-button {
            width: 16rem;
        }

    }

    &__last-profiles {
        align-items: stretch;
        ul {
            display: flex;
            flex-direction: column;
            gap: var(--margin-sm);
            li {
                list-style-type: none;
                display: flex;
                flex: 1 1;
                justify-content: space-between;
            }
        }
    }


}
