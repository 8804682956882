@import "../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import "../../style/util";

.c-menu-item {
    width: 20%;
    outline: none;
    border: none;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    background-color: transparent;
    color: $grey-95;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    user-select: none;
    @include textSize(75);

    &__container {
        display: flex;
        flex-direction: column;
        gap: $spacing-4;

        & .bepe-icon {
            font-size: 2rem;
        }
    }

    &--unselected:hover {
        background-color: $grey-50;
    }

    &:active {
        background-color: $black-100;
    }

    &--selected {
        border-bottom: 4px solid $accent;
    }
}

@include desktop {
    .c-menu-item {
        width: 100%;
        @include textSize(100);
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;
        border-left: 4px solid transparent;

        &__container {
            flex-direction: row;
            gap: $spacing-16;
            justify-content: flex-start;
            align-items: center;
    
            width: 100%;
            padding: $padding-lg calc($padding-xl - 4px);

            
            & .bepe-icon {
                font-size: 2.4rem;
            }
        }

        &--selected {
            background-color: $black-100;
            border-left: 4px solid $accent;
            border-bottom: 0px solid $black-100;
        }
    }
}