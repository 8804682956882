.c-currency-display {
    white-space: nowrap;

    text-align: right;
    
    &--negative {
        color: var(--signal-danger);
    }
    &--positive {
        color: var(--signal-confirm);
    }

    &--secret {
        filter: blur(4px);
    }
}
