@import "../../../../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import '../../../style/util'; 

.c-budget-plan-summary {
    display: flex;
    flex-direction: column;
    gap: var(--margin-sm);
    margin-bottom: var(--margin-lg);

    &__header-row {
      border-bottom: 1px solid var(--accent);
      position: sticky;
      &.c-budget-plan-summary__row h4 {
        font-size: var(--heading-50-size);
        line-height: var(--heading-50-line-height);
      }
    }
    &__total-row {
      border-top: 1px solid var(--accent);
    }


    &__reserve-row {
      .c-budget-plan-summary__header {
        cursor: pointer;
        &:hover {
          filter: brightness(0.8);
        }
      }
      &--excluded {
        filter: brightness(0.5);
        .c-budget-plan-summary__header:hover {
          filter: brightness(1.2);
        }
      }

    }

    &__row {
      display: flex;
      gap: var(--margin-lg);
      align-items: center;
      
      h4 {
        @include textSize(100);
      }
    }

    & .c-budget-plan-column {
      flex: 1 1 7.692307692307692%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: right;
    }

    &__header {
      display: flex;
      gap: var(--padding-sm);
      align-items: center;
      flex: 1 1 24.6245614035088%;
      overflow: hidden;

      &--ok {
        .c-budget-plan-summary__icon {
            color: var(--status-ok);
        }
      }
      &--warning {
        .c-budget-plan-summary__icon {
            color: var(--status-warning);
        }
      }
      &--info {
        .c-budget-plan-summary__icon {
          color: var(--status-info);
        }
      }
      &--caution {
          .c-budget-plan-summary__icon {
          color: var(--status-caution);
        }
      }
      &--neutral {
        .c-budget-plan-summary__icon {
          color: var(--status-neutral);
      }
    }
  }

  &__body {
    display: flex;
    gap: var(--padding-sm);
    flex: 1 1 75.3754385964912%;
    overflow: hidden;
    gap: var(--padding-sm);
  }

}



@include desktop {

}
