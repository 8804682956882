@import "../node_modules/@benjaminpetry/bepe-design/lib/bepe-design";
@import './style/util'; 

body {
  position: fixed;
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  // min-width: 100vw;
  overflow: hidden;
}

html {
  min-width: 100vw;
  height: -webkit-fill-available;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.c-app {
  width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  background-color: var(--black-100);
  color: var(--grey-95);
  overflow: hidden;
}

.c-app {
  height: 100%;
  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-16;
  }
}

header {
  height: 7.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  h1 {
      width: 100%;
      text-align: center;
  }
}

iframe {
  display: none !important;
}


.c-desktop-only {
    display: none !important;
    visibility: hidden !important;
}

@include desktop {
    .c-desktop-only {
        display: initial !important;
        visibility: inherit !important;
    }
    .c-mobile-only {
        display: none !important;
        visibility: hidden !important;
    }
}

.bepe-form-element__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}