@import '../../../../style/util'; 

.c-budget-label-module {
    display: flex;
    align-items: center;
    gap: var(--spacing-16);
    overflow: hidden;
    width: fit-content;
    justify-content: space-between;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
