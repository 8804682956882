@import '../../style/util'; 

.c-account-edit-view {
    .c-modal {
        .bepe-textfield {
            width: 100%;
        }

        .bepe-radiogroup {
            width: 100%;
        }
    }

    .c-modal__main {
        display: flex;
        flex-direction: column;
        gap: var(--margin-md);
    }
}

@include desktop {
    .c-account-edit-view {
        .c-modal {
            width: 30rem;
            min-width: 30rem;
        }
    }
}